export default function authHeader() {
  return {}
  // const user = JSON.parse(localStorage.getItem('user'));

  // if (user && user.authToken) {
  //   // console.log('user', user);
  //   return { Authorization: 'Bearer ' + user.authToken };
  //   // return { 'x-access-token': user.authToken };       // for Node.js Express back-end
  // } else {
  //   window.location = '/auth/login';
  //   // history.push('/auth/login');
  //   return {};
  // }
}