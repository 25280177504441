import React from "react";

import LandingHeader from "../header";
import LandingHero from "./hero";
import LandingCompanies from "./companies";
import LandingFeatures from "./features";
import LandingContent1 from "./content-1";
import LandingMain from "./main";
import LandingPeople from "./people";
import LandingPricing from "./pricing";
import LandingFreeTrail from "../freetrail";
import LandingFooter from "../footer";
import LandingClient from "./client";
import LandingSolutions from "./solutions";
import LandingRichMedia from "./richmedia";
import LandingWeMake from "./wemake";

export default function Landing() {
	return (
		<div className="da-landing da-bg-black-0 da-bg-dark-90">
			<LandingHeader />

			<LandingMain />
			{/* <LandingHero /> */}

			{/* <LandingCompanies /> */}

			<LandingFeatures />
			<LandingRichMedia />

			{/* <LandingContent1 /> */}

			<LandingWeMake />

			<LandingPeople />

			<LandingSolutions />

			<LandingClient />

			<LandingFreeTrail />

			<LandingFooter />
		</div>
	);
}
