import { Calendar, People, Buy, Password, Edit, Folder, Document } from "react-iconly";

const apps = [
	{
		header: "API",
	},
	{
		id: "api_docs",
		title: "Documentation",
		icon: <Document set="curved" />,
		navLink: "/user/api_docs/send_msg",
	},
	{
		id: "api_credentials",
		title: "API Credentials",
		icon: <Password set="curved" />,
		navLink: "/user/api_credentials",
	},
];

export default apps;
