import React from "react";

import { Avatar, Col, Row } from "antd";
import { Heart, Activity, TickSquare } from "react-iconly";
import { FaShopify, FaOpencart } from "react-icons/fa";
import { SiWoo } from "react-icons/si";
import peopleEmoji1 from "../../../assets/images/pages/landing/people-emoji-1.png";
import peopleEmoji2 from "../../../assets/images/pages/landing/people-emoji-2.png";
import peopleEmoji3 from "../../../assets/images/pages/landing/people-emoji-3.png";
import peopleEmoji4 from "../../../assets/images/pages/landing/people-emoji-4.png";
import peopleEmoji5 from "../../../assets/images/pages/landing/people-emoji-5.png";
import peopleEmoji6 from "../../../assets/images/pages/landing/people-emoji-6.png";
// import towWay from "../../../assets/images/pages/landing/2way_sample_4.svg";
import towWay from "../../../assets/images/pages/landing/two_way.png";

export default function LandingPeople() {
	const peopleValues = [
		{
			key: 1,
			avatar: peopleEmoji1,
			name: "Aaron Almaraz",
			title: "CEO & Founder at Gearat",
			comment:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.",
		},
		{
			key: 2,
			avatar: peopleEmoji2,
			name: "Phet Putrie",
			title: "Freelancer",
			comment:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.",
		},
		{
			key: 3,
			avatar: peopleEmoji3,
			name: "Lara Madrigal",
			title: "Marketing Specialist",
			comment:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.",
		},
		{
			key: 4,
			avatar: peopleEmoji4,
			name: "Marleah Eagleston",
			title: "Founder at Spicenet",
			comment: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots.",
		},
		{
			key: 5,
			avatar: peopleEmoji5,
			name: "Prescott MacCaffery",
			title: "Writer",
			comment: "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.",
		},
		{
			key: 6,
			avatar: peopleEmoji6,
			name: "Waiapi Karaka",
			title: "Marketing Specialist",
			comment: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots.",
		},
	];

	const peopleListMap = peopleValues.map((item, index) => (
		<Col key={item.key} md={8} span={24}>
			<div className="da-landing-people-item da-position-relative da-border-radius da-bg-black-0 da-bg-dark-90 da-p-sm-16 da-p-24">
				<div className="da-landing-people-item-twitter">
					<svg
						fill="#21C6DD"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 30 30"
						width="16px"
						height="16px"
					>
						<path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z" />
					</svg>
				</div>

				<Row align="middle">
					<Col>
						<img src={item.avatar} alt={item.name} className="da-landing-people-item-img" />
					</Col>

					<Col className="da-landing-people-item-title">
						<span className="da-d-block h5 da-font-weight-400">{item.name}</span>
						<span className="da-d-block da-caption da-font-weight-400 da-text-color-black-60 da-text-color-dark-40">
							{item.title}
						</span>
					</Col>
				</Row>

				<p className="h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16 da-mb-0">
					{item.comment}
				</p>
			</div>
		</Col>
	));

	return (
		<section className="da-landing-people da-py-sm-64 da-pt-96 da-pb-120 da-bg-black-10 da-bg-dark-100 da-position-relative da-overflow-hidden">
			<div className="da-landing-people-circle da-bg-success-1"></div>

			<div className="da-landing-container">
				<Row justify="center">
					{/* <Col xl={10} lg={15} md={18} span={24} className="da-text-center">
						<h2 className="h2 da-mb-4">10,000+ people love us</h2>

						<p className="h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-sm-0 da-mt-32 da-mb-0">
							These companies release their own versions of the operating systems with minor changes, and
							yet always.
						</p>
					</Col> */}

					<Col span={24} className="da-mt-64 da-mt-sm-0">
						<Row align="middle" justify="space-between">
							<Col xl={14} lg={10} span={24} className="da-pr-36">
								{/* <div className="da-landing-content-3-circle "></div> */}

								<div className="da-landing-content-3-img">
									<img src={towWay} alt="Perfect Solution For Small Business" />
								</div>
							</Col>

							<Col xl={10} lg={14} md={16} span={24}>
								<h2 className="h1 da-mb-32">Developer-friendly Whatsapp business API.</h2>

								<p className="h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mb-48">
									Our developer-friendly Whatsapp business API makes sending messages to your
									customers fast and easy.
								</p>

								<h4 className="h4 da-mb-32">Quick connect also</h4>

								<Row gutter={[16, 16]}>
									<Col span={24}>
										<Row align="middle">
											<Col>
												<Avatar
													className=""
													size={60}
													icon={
														<FaShopify
															className="da-ml-0"
															style={{
																width: 24,
																height: 24,
																marginLeft: 0,
															}}
														/>
													}
												/>
											</Col>

											<Col className="da-ml-16">
												<h5 className="h5 da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0">
													Shopify
												</h5>
												<p className="da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mb-0">
													Coming shortly
												</p>
											</Col>
										</Row>
									</Col>
									<Col span={24}>
										<Row align="middle">
											<Col>
												<Avatar
													className=""
													size={60}
													icon={
														<SiWoo
															className="da-ml-0"
															style={{
																width: 24,
																height: 24,
																marginLeft: 0,
															}}
														/>
													}
												/>
											</Col>

											<Col className="da-ml-16">
												<h5 className="h5 da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0">
													WooCommerce
												</h5>
												<p className="da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mb-0">
													Coming shortly
												</p>
											</Col>
										</Row>
									</Col>

									<Col span={24}>
										<Row align="middle">
											<Col>
												<Avatar
													className=""
													size={60}
													icon={
														<FaOpencart
															className="da-ml-0"
															style={{
																width: 24,
																height: 24,
																marginLeft: 0,
															}}
														/>
													}
												/>
											</Col>

											<Col className="da-ml-16">
												<h5 className="h5 da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0">
													Opencart
												</h5>
												<p className="da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mb-0">
													Coming shortly
												</p>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
		</section>
	);
}
