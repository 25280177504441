import axios from 'axios';
import Config from '../app.config';
axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;
const instance = axios.create({
  withCredentials: true,
  baseURL: Config.apiPath,
});

/**
 * Catch the AunAuthorized Request
 */
instance.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    window.location = '/auth/login';
    return;
  }
  return Promise.reject(error);
});

export default instance;