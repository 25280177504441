import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}/api/v1/auth/`;
// const API_URL = "http://192.168.0.130:3001/api/v1/auth/";
// const API_URL = "http://localhost:3001/api/v1/auth/";

const getCountryCode = () => {
	return axios.get(`${process.env.REACT_APP_API_URL}/api/v1/public/country_code`, { withCredentials: true, });
};

const getDirectPlan = (params) => {
	return axios.get(`${process.env.REACT_APP_API_URL}/api/v1/public/plan`, { withCredentials: true, params: { billed: params.billed ? "yearly" : "monthly", currency_type: params.currencyType }, });
};

const postContactUs = (params) => {
	return axios.post(`${process.env.REACT_APP_API_URL}/api/v1/public/contact_us`, params, { withCredentials: true, });
};

const postNewsLetter = (params) => {
	return axios.post(`${process.env.REACT_APP_API_URL}/api/v1/public/news_letter`, params, { withCredentials: true, });
};

const sendOtp = (mobile_no, country_code,) => {
	return axios.post(API_URL + "send_otp", {
		mobile_no,
		country_code,
	});
};

const verifyOtp = (mobile_no, country_code, password, otp, full_name) => {
	return axios.post(API_URL + "verify_otp", {
		mobile_no,
		country_code,
		password,
		otp,
		full_name
	});
};

const forgotSendOtp = (mobile_no, country_code,) => {
	return axios.post(API_URL + "forgot_send_otp", {
		mobile_no,
		country_code,
	});
};

const forgotVerifyOtp = (mobile_no, country_code, password, otp) => {
	return axios.post(API_URL + "forgot_verify_otp", {
		mobile_no,
		country_code,
		password,
		otp,
	});
};

const login = (country_code, mobile_no, password) => {
	return axios
		.post(API_URL + "login", {
			country_code,
			mobile_no,
			password,
		})
};

const getCurrentUser = () => {
	return JSON.parse(localStorage.getItem("user"));
};

export default {
	getCountryCode,
	sendOtp,
	verifyOtp,
	login,
	getCurrentUser,
	forgotSendOtp,
	forgotVerifyOtp,
	getDirectPlan,
	postContactUs,
	postNewsLetter,
};
