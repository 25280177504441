import React from "react";

import { Col, Row } from "antd";

export default function LandingCompanies() {
	return (
		<section
			className="da-landing-companies da-overflow-hidden da-pt-96 da-pb-120"
			style={{ paddingTop: "180px", paddingBottom: "180px" }}
		>
			<div className="da-landing-container">
				<Row className="da-mb-96" justify="center">
					<Col xl={15} lg={15} md={18} span={24} className="da-text-center">
						<h2 className="h2 da-mb-4">We have Wotty Solutions for</h2>

						<p className="h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-sm-0 da-mt-32 da-mb-0">
							Some of the industries that we serve
						</p>
					</Col>
				</Row>
				<Row gutter={[50, 24]} align="middle" justify="center">
					<Col className="da-text-center" xl={4} lg={4} md={6} sm={8} xs={8} span={8}>
						<h1>❤️</h1>
						<Col className="da-px-0">
							<h4>Brands</h4>
						</Col>
					</Col>
					<Col className="da-text-center" xl={4} lg={4} md={6} sm={8} xs={8} span={8}>
						<h1>📽️</h1>
						<Col className="da-px-0">
							<h4>Media</h4>
						</Col>
					</Col>
					<Col className="da-text-center" xl={4} lg={4} md={6} sm={8} xs={8} span={8}>
						<h1>🎯</h1>
						<Col className="da-px-0">
							<h4>Agencies</h4>
						</Col>
					</Col>
					<Col className="da-text-center" xl={4} lg={4} md={6} sm={8} xs={8} span={8}>
						<h1>🦸‍♀️</h1>
						<Col className="da-px-0">
							<h4>Influencers</h4>
						</Col>
					</Col>
					<Col className="da-text-center" xl={4} lg={4} md={6} sm={8} xs={8} span={8}>
						<h1>🚀</h1>
						<Col className="da-px-0">
							<h4>Startups</h4>
						</Col>
					</Col>
					<Col className="da-text-center" xl={4} lg={4} md={6} sm={8} xs={8} span={8}>
						<h1>🛒</h1>
						<Col className="da-px-0" style={{ whiteSpace: "nowrap" }}>
							<h4>E-commerce</h4>
						</Col>
					</Col>
				</Row>
			</div>
		</section>
	);
}
