import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import { Layout, Button, Row, Col, Tooltip } from "antd";
import { RiCloseLine, RiMenuFill } from "react-icons/ri";
import { Search, Ticket } from "react-iconly";

import HeaderSearch from "./HeaderSearch";
import HeaderUser from "./HeaderUser";
import HeaderNotifications from "./HeaderNotifications";
import HeaderCart from "./HeaderCart";
import HeaderText from "./HeaderText";
const { Header } = Layout;

export default function MenuHeader(props) {
	const { setVisible } = props;

	const [searchHeader, setSearchHeader] = useState(false);
	const [searchActive, setSearchActive] = useState(false);

	// Redux
	// const customise = useSelector(state => state.customise)

	// Focus
	const inputFocusRef = useRef(null);
	const inputFocusProp = {
		ref: inputFocusRef,
	};

	// Search Active
	setTimeout(() => setSearchActive(searchHeader), 100);

	const searchClick = () => {
		setSearchHeader(true);

		setTimeout(() => {
			inputFocusRef.current.focus({
				cursor: "start",
			});
		}, 200);
	};

	// Mobile Sidebar
	const showDrawer = () => {
		setVisible(true);
		setSearchHeader(false);
	};

	// Children
	const headerChildren = () => {
		return (
			<Row className="da-w-100 da-position-relative" align="middle" justify="space-between">
				<Col className="da-mobile-sidebar-button da-mr-24">
					<Button
						className="da-mobile-sidebar-button"
						type="text"
						onClick={showDrawer}
						icon={
							<RiMenuFill size={24} className="remix-icon da-text-color-black-80 da-text-color-dark-30" />
						}
					/>
				</Col>

				{/* <Col
					flex="1"
					style={{ display: !searchHeader ? "none" : "block" }}
					className={`da-pr-md-0 da-pr-16 da-header-search ${searchActive && "da-header-search-active"}`}
				>
					<HeaderSearch inputFocusProp={inputFocusProp} setSearchHeader={setSearchHeader} />
				</Col> */}

				<HeaderText />

				<Col>
					<Row align="middle">
						{/* <Col className="da-d-flex-center da-mr-4">
							{!searchHeader ? (
								<Button
									type="text"
									icon={<Search set="curved" className="da-text-color-black-60" />}
									onClick={() => searchClick()}
								/>
							) : (
								<Button
									type="text"
									icon={<RiCloseLine size={24} className="da-text-color-black-60" />}
									onClick={() => setSearchHeader(false)}
								/>
							)}
						</Col> */}

						<Tooltip
							placement="left"
							title="Support"
							onConfirm={confirm}
							okText="Yes"
							cancelText="No"
							className="hp-ml-8"
						>
							<Link to="/user/contact_us">
								<Col className="da-d-flex-center da-mr-4 da-basket-dropdown-button">
									<Button
										type="text"
										icon={<Ticket set="curved" className="da-text-color-black-60" />}
									/>
								</Col>
							</Link>
						</Tooltip>

						<HeaderNotifications />

						<HeaderUser />
					</Row>
				</Col>
			</Row>
		);
	};

	return (
		<Header>
			<Row justify="center" className="da-w-100">
				<Col span={24}>{headerChildren()}</Col>
			</Row>
		</Header>
	);
}
