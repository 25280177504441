import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Col, Row, Tag } from "antd";
import { Document, TicketStar } from "react-iconly";

import emoji1 from "../../../assets/images/pages/landing/emoji-1.png";
import emoji2 from "../../../assets/images/pages/landing/emoji-2.png";
import emoji3 from "../../../assets/images/pages/landing/emoji-3.png";
import emoji4 from "../../../assets/images/pages/landing/emoji-4.png";
// import content2Image from "../../../assets/images/pages/landing/whatsapi.png";
// import content2Image from "../../../assets/images/pages/landing/content-2.svg";
// import content2Image from "../../../assets/images/pages/landing/test_wotty_bg.svg";
// import content2Image from "../../../assets/images/pages/landing/test_wotty_bg_2.svg";
import content2Image from "../../../assets/images/pages/landing/main_wotty_hero_banner.png";
// import content2Image from "../../../assets/images/pages/landing/test_wotty_bg_3.svg";
// import content2Image from "../../../assets/images/pages/landing/test_wotty_bg_2.png";

export default function LandingMain() {
	return (
		<section className="da-landing-content-2 da-pb-sm-64 da-pb-96 da-overflow-hidden">
			<div className="da-landing-container da-mt-sm-0 da-mb-0">
				<Row align="middle" justify="space-between">
					<Col lg={11} md={18} span={24}>
						<Tag className="da-mb-10" color="processing">
							<div
								className="da-font-weight-500 da-mb-0 da-py-4"
								style={{ color: "#0BB47B", fontSize: 14 }}
							>
								WhatsApp Business API
							</div>
						</Tag>
						<h1 className="h1 da-mb-24">Create engaging customer experiences with Wotty</h1>

						<h4 className="h4 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mb-48">
							The ultimate solution designed to increase your customer engagement exponentially with the
							WhatsApp API
						</h4>

						<Row gutter={[16, 16]} className="da-landing-content-2-list">
							<Col span={24} className="da-px-8">
								<Link to="/auth/register">
									<Button className="da-mr-16" type="primary">
										Start for free now
									</Button>
								</Link>
								<Link to="/auth/login">
									<Button type="">Login</Button>
								</Link>
							</Col>
						</Row>
					</Col>
					<Col lg={13} span={24}>
						{/* <div className="da-landing-content-2-circle da-position-relative da-bg-info-1"> */}
						<div className="da-landing-content-2-circle">
							<img
								src={content2Image}
								alt="Work anywhere, with any device"
								className=""
								style={{
									width: "100%",
									height: "auto",
								}}
							/>
						</div>
					</Col>
				</Row>
				{/* <div className="da-landing-content-2-left">
					<div className="da-landing-content-2-emoji">💌</div>

					<div className="da-landing-content-2-emoji">🔔</div>

					<div className="da-landing-content-2-emoji">😎</div>

					<div className="da-landing-content-2-emoji">📎</div>
				</div>

				<div className="da-landing-content-2-right">
					<div className="da-landing-content-2-emoji">👋</div>

					<div className="da-landing-content-2-emoji">🔗</div>

					<div className="da-landing-content-2-emoji">🖼️</div>
				</div> */}
			</div>
		</section>
	);
}
