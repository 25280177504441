import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import { Menu, Dropdown, Col, Avatar } from "antd";
import { User, People, InfoSquare, Calendar, Discount, Logout, Ticket } from "react-iconly";

import avatar from "../../../assets/images/memoji/avatar_getwotty.png";
import userService from "../../../services/user.service";

export default function HeaderUser() {
	// const customise = useSelector(state => state.customise)

	const onclickUserLogout = async () => {
		let response = await userService.logout();
		console.log("logout", response);
		return true;
	};
	const menu = (
		<Menu theme="light">
			<Menu.Item
				key={0}
				icon={
					<User set="curved" className="remix-icon da-vertical-align-middle da-text-color-dark-0" size={16} />
				}
				className="da-text-color-dark-0"
			>
				<Link to="/user/profile/details">Profile</Link>
			</Menu.Item>

			<Menu.Item
				key={1}
				icon={
					<Ticket
						set="curved"
						className="remix-icon da-vertical-align-middle da-text-color-dark-0"
						size={16}
					/>
				}
				className="da-text-color-dark-0"
			>
				<Link to="/user/contact_us">Support</Link>
			</Menu.Item>

			{/* <Menu.Item
				key={2}
				icon={
					<Calendar
						set="curved"
						className="remix-icon da-vertical-align-middle da-text-color-dark-0"
						size={16}
					/>
				}
				className="da-text-color-dark-0"
			>
				<Link to="/apps/calendar">Calendar</Link>
			</Menu.Item>

			<Menu.Item
				key={3}
				icon={
					<Discount
						set="curved"
						className="remix-icon da-vertical-align-middle da-text-color-dark-0"
						size={16}
					/>
				}
				className="da-text-color-dark-0"
			>
				<Link to="/pages/pricing">Pricing</Link>
			</Menu.Item> */}

			<Menu.Item
				key={4}
				icon={
					<InfoSquare
						set="curved"
						className="remix-icon da-vertical-align-middle da-text-color-dark-0"
						size={16}
					/>
				}
				className="da-text-color-dark-0"
			>
				<a href="/pages/faq" target="_blank">
					FAQ
				</a>
				{/* <Link to="/pages/faq"></Link> */}
			</Menu.Item>

			<Menu.Item
				key={5}
				icon={
					<Logout
						set="curved"
						className="remix-icon da-vertical-align-middle da-text-color-dark-0"
						size={16}
					/>
				}
				className="da-text-color-dark-0"
			>
				<Link to="/auth/login" onClick={onclickUserLogout}>
					Logout
				</Link>
			</Menu.Item>
		</Menu>
	);

	return (
		<Col>
			<Dropdown overlay={menu} placement="bottomLeft">
				<Col className="da-d-flex-center" onClick={(e) => e.preventDefault()}>
					<Avatar src={avatar} size={40} />
				</Col>
			</Dropdown>
		</Col>
	);
}
