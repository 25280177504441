import { Home, Graph, Document, Scan } from "react-iconly";
import { Calendar, People, Buy } from "react-iconly";

const main = [
	{
		header: "MAIN",
	},
	{
		id: "main_dashboard",
		title: "Dashboard",
		icon: <Home set="curved" />,
		navLink: "/user/dashboard",
	},
];

export default main;
