import { Calendar, People, Buy, Chat, Edit } from "react-iconly";

const apps = [
    {
        header: "APPS",
    },
    {
        id: "quick_msg",
        title: "Quick Message",
        icon: <Chat set="curved"/>,
        navLink: "/user/quick_msg",
    },
    {
        id: "auto_reply",
        title: "Auto Reply",
        icon: <Edit set="curved"/>,
        navLink: "/user/auto_reply",
    },
];

export default apps