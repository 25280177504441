import React from "react";

import { Tabs, Avatar, Col, Row } from "antd";
import { Heart, Activity, TickSquare } from "react-iconly";

import {
	RiTimerFill,
	RiInformationLine,
	RiHome2Line,
	RiMailLine,
	RiNotification3Line,
	RiNotification3Fill,
	RiMapPinTimeFill,
	RiInformationFill,
	RiCustomerServiceFill,
	RiSearchFill,
	RiMapPin2Fill,
	RiChatSmile2Fill,
	RiShieldKeyholeFill,
	RiMoneyDollarCircleFill,
} from "react-icons/ri";
import { BiBot, BiCart } from "react-icons/bi";
import { SiTarget } from "react-icons/si";
import { CgShoppingCart } from "react-icons/cg";
import { HiTag, HiTicket, HiLockClosed, HiTruck, HiOutlineDocumentText } from "react-icons/hi";
import { BsMegaphone } from "react-icons/bs";
import { VscMegaphone } from "react-icons/vsc";
import { IoAirplaneSharp, IoMegaphoneOutline } from "react-icons/io5";
import { IoMdChatbubbles, IoMdRefreshCircle } from "react-icons/io";
import { MdQuickreply, MdSecurity, MdFreeCancellation, MdOutlineHeadsetMic } from "react-icons/md";
import { FaWarehouse, FaTshirt, FaDollarSign, FaCalendarCheck, FaTruck, FaCalendarTimes } from "react-icons/fa";
import content1Image from "../../../assets/images/pages/landing/content-1.svg";
import content1Image2 from "../../../assets/images/pages/landing/content-1-2.svg";
import content1Image3 from "../../../assets/images/pages/landing/getwotty_cinema.png";
import content1Image4 from "../../../assets/images/pages/landing/getwotty_bank.png";
import content1Image5 from "../../../assets/images/pages/landing/getwotty_care.png";
import content1Image6 from "../../../assets/images/pages/landing/getwotty_ecomm.png";
import content1Image7 from "../../../assets/images/pages/landing/getwotty_fashion.png";
import content1Image8 from "../../../assets/images/pages/landing/getwotty_chatbot.png";
import content1Image9 from "../../../assets/images/pages/landing/getwotty_notification.png";

export default function LandingWeMake() {
	const { TabPane } = Tabs;

	function callback(key) {
		console.log(key);
	}

	return (
		<section className="da-landing-content-1 da-overflow-hidden da-pt-96 da-pb-120 da-py-sm-64 da-py-120">
			<div className="da-landing-container ">
				<Tabs defaultActiveKey="1" size="large" onChange={callback}>
					<TabPane
						tab={
							<span>
								<RiNotification3Line className="remix-icon" />
								Notification
							</span>
						}
						key="1"
					>
						<Row align="middle" justify="space-between">
							<Col xl={10} lg={14} md={16} span={24}>
								<h2 className="h1 da-mb-32">Automatic notifications and alerts</h2>

								<p className="h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mb-48 da-pr-sm-0 da-pr-96">
									Send order updates, delivery alerts, reminders, and more at a fraction of the cost
									of traditional messaging channels, while achieving high open rates
								</p>

								<Row gutter={[16, 16]}>
									<Col span={24}>
										<Row gutter={[16, 16]} align="middle">
											<Col md={4} xs={5} sm={3}>
												<Avatar
													size={60}
													icon={
														<RiNotification3Fill
															className="da-ml-0"
															style={{ width: 24, height: 24, marginLeft: 0 }}
														/>
													}
												/>
											</Col>

											<Col className="" sm={21} md={20} xs={19}>
												<h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0 da-ml-sm-8">
													General notifications
												</h3>
											</Col>
										</Row>
									</Col>

									<Col span={24}>
										<Row gutter={[16, 16]} align="middle">
											<Col md={4} xs={5} sm={3}>
												<Avatar
													size={60}
													icon={
														<RiTimerFill
															className="da-ml-0"
															style={{ width: 24, height: 24, marginLeft: 0 }}
														/>
													}
												/>
											</Col>

											<Col className="" sm={21} md={20} xs={19}>
												<h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0 da-ml-sm-8">
													Policy expire
												</h3>
											</Col>
										</Row>
									</Col>

									<Col span={24}>
										<Row gutter={[16, 16]} align="middle">
											<Col md={4} xs={5} sm={3}>
												<Avatar
													size={60}
													icon={
														<FaTruck
															className="da-ml-0"
															style={{ width: 24, height: 24, marginLeft: 0 }}
														/>
													}
												/>
											</Col>

											<Col className="" sm={21} md={20} xs={19}>
												<h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0 da-ml-sm-8">
													Delivery package handling
												</h3>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>

							<Col xl={14} lg={10} span={24} className="da-mt-md-64">
								{/* <div className="da-landing-content-1-circle "></div> */}

								<div className="da-landing-content-1-img">
									<img src={content1Image9} alt="Perfect Solution For Small Business" />
								</div>
							</Col>
						</Row>
					</TabPane>

					<TabPane
						tab={
							<span>
								<HiOutlineDocumentText className="remix-icon" />
								Execute
							</span>
						}
						key="2"
					>
						<Row align="middle" justify="space-between">
							<Col xl={10} lg={14} md={16} span={24}>
								<h2 className="h1 da-mb-32">Perform automate transactional notifications & updates</h2>

								<p className="h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mb-48 da-pr-sm-0 da-pr-96">
									Send transactions such as travel bookings, rescheduling a delivery, and account
									opening using Wotty
								</p>

								<Row gutter={[16, 16]}>
									<Col span={24}>
										<Row gutter={[16, 16]} align="middle">
											<Col md={4} xs={5} sm={3}>
												<Avatar
													size={60}
													icon={
														<IoAirplaneSharp
															className="da-ml-0"
															style={{ width: 24, height: 24, marginLeft: 0 }}
														/>
													}
												/>
											</Col>

											<Col className="" sm={21} md={20} xs={19}>
												<h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0 da-ml-sm-8">
													Travel bookings
												</h3>
											</Col>
										</Row>
									</Col>

									<Col span={24}>
										<Row gutter={[16, 16]} align="middle">
											<Col md={4} xs={5} sm={3}>
												<Avatar
													size={60}
													icon={
														<RiMapPinTimeFill
															className="da-ml-0"
															style={{ width: 24, height: 24, marginLeft: 0 }}
														/>
													}
												/>
											</Col>

											<Col className="" sm={21} md={20} xs={19}>
												<h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0 da-ml-sm-8">
													Rescheduling a delivery
												</h3>
											</Col>
										</Row>
									</Col>

									<Col span={24}>
										<Row gutter={[16, 16]} align="middle">
											<Col md={4} xs={5} sm={3}>
												<Avatar
													size={60}
													icon={
														<FaCalendarTimes
															className="da-ml-0"
															style={{ width: 24, height: 24, marginLeft: 0 }}
														/>
													}
												/>
											</Col>

											<Col className="" sm={21} md={20} xs={19}>
												<h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0 da-ml-sm-8">
													Canceling an order
												</h3>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>

							<Col xl={14} lg={10} span={24} className="da-mt-md-64">
								{/* <div className="da-landing-content-1-circle da-bg-success-1"></div> */}

								<div className="da-landing-content-1-img">
									<img src={content1Image3} alt="Perfect Solution For Small Business" />
								</div>
							</Col>
						</Row>
					</TabPane>
					<TabPane
						tab={
							<span>
								<RiInformationLine className="remix-icon" />
								Retrieve
							</span>
						}
						key="3"
					>
						<Row align="middle" justify="space-between">
							<Col xl={10} lg={14} md={16} span={24}>
								<h2 className="h1 da-mb-32">Retrieve information automatically</h2>

								<p className="h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mb-48 da-pr-sm-0 da-pr-96">
									You can retrieve information such as account balance info, profile information,
									product warranty details, tracking services, etc.
								</p>

								<Row gutter={[16, 16]}>
									<Col span={24}>
										<Row gutter={[16, 16]} align="middle">
											<Col md={4} xs={5} sm={3}>
												<Avatar
													size={60}
													icon={
														<RiMoneyDollarCircleFill
															className="da-ml-0"
															style={{ width: 24, height: 24, marginLeft: 0 }}
														/>
													}
												/>
											</Col>

											<Col className="" sm={21} md={20} xs={19}>
												<h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0 da-ml-sm-8">
													Balance info
												</h3>
											</Col>
										</Row>
									</Col>

									<Col span={24}>
										<Row gutter={[16, 16]} align="middle">
											<Col md={4} xs={5} sm={3}>
												<Avatar
													size={60}
													icon={
														<RiInformationFill
															className="da-ml-0"
															style={{ width: 24, height: 24, marginLeft: 0 }}
														/>
													}
												/>
											</Col>

											<Col className="" sm={21} md={20} xs={19}>
												<h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0 da-ml-sm-8">
													Product warranty detail
												</h3>
											</Col>
										</Row>
									</Col>

									<Col span={24}>
										<Row gutter={[16, 16]} align="middle">
											<Col md={4} xs={5} sm={3}>
												<Avatar
													size={60}
													icon={
														<RiShieldKeyholeFill
															className="da-ml-0"
															style={{ width: 24, height: 24, marginLeft: 0 }}
														/>
													}
												/>
											</Col>

											<Col className="" sm={21} md={20} xs={19}>
												<h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0 da-ml-sm-8">
													Security tracking
												</h3>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>

							<Col xl={14} lg={10} span={24} className="da-mt-md-64">
								{/* <div className="da-landing-content-1-circle da-bg-success-1"></div> */}

								<div className="da-landing-content-1-img">
									<img src={content1Image4} alt="Perfect Solution For Small Business" />
								</div>
							</Col>
						</Row>
					</TabPane>
					<TabPane
						tab={
							<span>
								<MdOutlineHeadsetMic className="remix-icon" />
								Interact
							</span>
						}
						key="4"
					>
						<Row align="middle" justify="space-between">
							<Col xl={10} lg={14} md={16} span={24}>
								<h2 className="h1 da-mb-32">Provide real-time interaction to your customers</h2>

								<p className="h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mb-48 da-pr-sm-0 da-pr-96">
									Help customers with “How to" questions, “I am unable to” concerns, “Can I”
									clarifications and much more
								</p>

								<Row gutter={[16, 16]}>
									<Col span={24}>
										<Row gutter={[16, 16]} align="middle">
											<Col md={4} xs={5} sm={3}>
												<Avatar
													size={60}
													icon={
														<RiCustomerServiceFill
															className="da-ml-0"
															style={{ width: 24, height: 24, marginLeft: 0 }}
														/>
													}
												/>
											</Col>

											<Col className="" sm={21} md={20} xs={19}>
												<h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0 da-ml-sm-8">
													Help customer with a query
												</h3>
											</Col>
										</Row>
									</Col>

									<Col span={24}>
										<Row gutter={[16, 16]} align="middle">
											<Col md={4} xs={5} sm={3}>
												<Avatar
													size={60}
													icon={
														<HiTicket
															className="da-ml-0"
															style={{ width: 24, height: 24, marginLeft: 0 }}
														/>
													}
												/>
											</Col>

											<Col className="" sm={21} md={20} xs={19}>
												<h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0 da-ml-sm-8">
													Create a ticket
												</h3>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>

							<Col xl={14} lg={10} span={24} className="da-mt-md-64">
								{/* <div className="da-landing-content-1-circle da-bg-success-1"></div> */}

								<div className="da-landing-content-1-img">
									<img src={content1Image5} alt="Perfect Solution For Small Business" />
								</div>
							</Col>
						</Row>
					</TabPane>
					<TabPane
						tab={
							<span>
								<IoMegaphoneOutline className="remix-icon" />
								Marketing
							</span>
						}
						key="5"
					>
						<Row align="middle" justify="space-between">
							<Col xl={10} lg={14} md={16} span={24}>
								<h2 className="h1 da-mb-32">Promote your brand through WhatsApp bot</h2>

								<p className="h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mb-48 da-pr-sm-0 da-pr-96">
									The beauty of using WhatsApp bots in marketing is that it uses automation to help
									you stay in touch with your customer
								</p>

								<Row gutter={[16, 16]}>
									<Col span={24}>
										<Row gutter={[16, 16]} align="middle">
											<Col md={4} xs={5} sm={3}>
												<Avatar
													size={60}
													icon={
														<SiTarget
															className="da-ml-0"
															style={{ width: 24, height: 24, marginLeft: 0 }}
														/>
													}
												/>
											</Col>

											<Col className="" sm={21} md={20} xs={19}>
												<h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0 da-ml-sm-8">
													Target and acquire customer
												</h3>
											</Col>
										</Row>
									</Col>

									<Col span={24}>
										<Row gutter={[16, 16]} align="middle">
											<Col md={4} xs={5} sm={3}>
												<Avatar
													size={60}
													icon={
														<FaTshirt
															className="da-ml-0"
															style={{ width: 24, height: 24, marginLeft: 0 }}
														/>
													}
												/>
											</Col>

											<Col className="" sm={21} md={20} xs={19}>
												<h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0 da-ml-sm-8">
													Promote new products
												</h3>
											</Col>
										</Row>
									</Col>

									<Col span={24}>
										<Row gutter={[16, 16]} align="middle">
											<Col md={4} xs={5} sm={3}>
												<Avatar
													size={60}
													icon={
														<HiTag
															className="da-ml-0"
															style={{ width: 24, height: 24, marginLeft: 0 }}
														/>
													}
												/>
											</Col>

											<Col className="" sm={21} md={20} xs={19}>
												<h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0 da-ml-sm-8">
													Deals and discount offers
												</h3>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>

							<Col xl={14} lg={10} span={24} className="da-mt-md-64">
								{/* <div className="da-landing-content-1-circle da-bg-success-1"></div> */}

								<div className="da-landing-content-1-img">
									<img src={content1Image7} alt="Perfect Solution For Small Business" />
								</div>
							</Col>
						</Row>
					</TabPane>
					<TabPane
						tab={
							<span>
								<CgShoppingCart className="remix-icon" />
								E-commerce
							</span>
						}
						key="6"
					>
						<Row align="middle" justify="space-between">
							<Col xl={10} lg={14} md={16} span={24}>
								<h2 className="h1 da-mb-32">Make online shopping easy for your customers</h2>

								<p className="h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mb-48 da-pr-sm-0 da-pr-96">
									Help your customers complete the purchase of products by providing better images,
									gifs, or videos of the products
								</p>

								<Row gutter={[16, 16]}>
									<Col span={24}>
										<Row gutter={[16, 16]} align="middle">
											<Col md={4} xs={5} sm={3}>
												<Avatar
													size={60}
													icon={
														<RiSearchFill
															className="da-ml-0"
															style={{ width: 24, height: 24, marginLeft: 0 }}
														/>
													}
												/>
											</Col>

											<Col className="" sm={21} md={20} xs={19}>
												<h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0 da-ml-sm-8">
													Product search
												</h3>
											</Col>
										</Row>
									</Col>

									<Col span={24}>
										<Row gutter={[16, 16]} align="middle">
											<Col md={4} xs={5} sm={3}>
												<Avatar
													size={60}
													icon={
														<FaWarehouse
															className="da-ml-0"
															style={{ width: 24, height: 24, marginLeft: 0 }}
														/>
													}
												/>
											</Col>

											<Col className="" sm={21} md={20} xs={19}>
												<h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0 da-ml-sm-8">
													New product stock
												</h3>
											</Col>
										</Row>
									</Col>

									<Col span={24}>
										<Row gutter={[16, 16]} align="middle">
											<Col md={4} xs={5} sm={3}>
												<Avatar
													size={60}
													icon={
														<RiMapPin2Fill
															className="da-ml-0"
															style={{ width: 24, height: 24, marginLeft: 0 }}
														/>
													}
												/>
											</Col>

											<Col className="" sm={21} md={20} xs={19}>
												<h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0 da-ml-sm-8">
													Order placing and tracking
												</h3>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>

							<Col xl={14} lg={10} span={24} className="da-mt-md-64">
								{/* <div className="da-landing-content-1-circle da-bg-success-1"></div> */}

								<div className="da-landing-content-1-img">
									<img src={content1Image6} alt="Perfect Solution For Small Business" />
								</div>
							</Col>
						</Row>
					</TabPane>
					<TabPane
						tab={
							<span>
								<BiBot className="remix-icon" />
								ChatBot
							</span>
						}
						key="7"
					>
						<Row align="middle" justify="space-between">
							<Col xl={10} lg={14} md={16} span={24}>
								<h2 className="h1 da-mb-32">WhatsApp chatbot lets you connect with your customers</h2>

								<p className="h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mb-48 da-pr-sm-0 da-pr-96">
									With WhatsApp Chatbot, your business can leverage the power of instant communication
									with quick, automated responses throughout the day to keep your customers satisfied
									with your promptness
								</p>

								<Row gutter={[16, 16]}>
									<Col span={24}>
										<Row gutter={[16, 16]} align="middle">
											<Col md={4} xs={5} sm={3}>
												<Avatar
													size={60}
													icon={
														<IoMdChatbubbles
															className="da-ml-0"
															style={{ width: 24, height: 24, marginLeft: 0 }}
														/>
													}
												/>
											</Col>

											<Col className="" sm={21} md={20} xs={19}>
												<h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0 da-ml-sm-8">
													Automate conversations
												</h3>
											</Col>
										</Row>
									</Col>

									<Col span={24}>
										<Row gutter={[16, 16]} align="middle">
											<Col md={4} xs={5} sm={3}>
												<Avatar
													size={60}
													icon={
														<RiChatSmile2Fill
															className="da-ml-0"
															style={{ width: 24, height: 24, marginLeft: 0 }}
														/>
													}
												/>
											</Col>

											<Col className="" sm={21} md={20} xs={19}>
												<h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0 da-ml-sm-8">
													Easy communication
												</h3>
											</Col>
										</Row>
									</Col>

									<Col span={24}>
										<Row gutter={[16, 16]} align="middle">
											<Col md={4} xs={5} sm={3}>
												<Avatar
													size={60}
													icon={
														<MdQuickreply
															className="da-ml-0"
															style={{ width: 24, height: 24, marginLeft: 0 }}
														/>
													}
												/>
											</Col>

											<Col className="" sm={21} md={20} xs={19}>
												<h3 className="da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0 da-ml-sm-8">
													Instant answer
												</h3>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>

							<Col xl={14} lg={10} span={24} className="da-mt-md-64">
								{/* <div className="da-landing-content-1-circle da-bg-success-1"></div> */}

								<div className="da-landing-content-1-img">
									<img src={content1Image8} alt="Perfect Solution For Small Business" />
								</div>
							</Col>
						</Row>
					</TabPane>
				</Tabs>
			</div>
		</section>
	);
}
