import React from "react";

import { Button, Row, Col } from "antd";

import clientImage from "../../../assets/images/pages/landing/client_final_whatsbot.png";

export default function LandingClient() {
	return (
		<section className="da-landing-client da-py-sm-64 da-pt-96 da-pb-120 da-bg-black-10 da-bg-dark-100 da-position-relative da-overflow-hidden">
			<div className="da-landing-client-circle da-bg-success-1"></div>

			<div className="da-landing-container">
				<Row justify="center">
					<Col span={24} className="da-landing-client-image da-px-16 da-text-center da-mb-32">
						<img
							className=""
							src={clientImage}
							alt=""
							// style={{
							// 	marginTop: "-180px",
							// }}
						/>
					</Col>
					<Col xl={16} lg={15} md={18} span={24} className="da-text-center">
						<h1 className="h1 da-mb-10">Talk to 2 Billion+ People on WhatsApp</h1>

						<p className="h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-sm-0 da-mt-32 da-mb-0">
							Get your own WhatsApp Business account and deliver a truly multi-channel experience to your
							customers on the largest messaging app in the world
						</p>
					</Col>
				</Row>
			</div>
		</section>
	);
}
