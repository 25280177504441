import axios from "./api";
import authHeader from "./auth-header";

const API_URL = `${process.env.REACT_APP_API_URL}/api/v1/`;
// const API_URL = "http://192.168.0.130:3001/api/v1/user/";
// const API_URL = "http://localhost:3001/api/v1/user/";

const getDashboard = () => {
	return axios.get(API_URL + "user/dashboard", { headers: authHeader(), withCredentials: true, });
};

const getSavedDetails = async (params) => {
	let details = {
		"name": localStorage.getItem("name"),
		"mobile_no": localStorage.getItem("mobile_no"),
	}
	return details;
};

const getReceiveMsg = (params) => {
	return axios.get(API_URL + "user/receive_message", { headers: authHeader(), withCredentials: true, params: { page: params.current, limit: params.pageSize, search: '' || params.search }, });
};

const getSendMsg = (params) => {
	return axios.get(API_URL + "user/send_message", { headers: authHeader(), withCredentials: true, params: { page: params.current, limit: params.pageSize, search: params.search || "" }, });
};

const getAutoReply = (params) => {
	return axios.get(API_URL + "user/auto_reply", { headers: authHeader(), withCredentials: true, params: { page: params.current, limit: params.pageSize, search: '' || params.search }, });
};

const getPlan = (params) => {
	console.log('params', params);
	return axios.get(API_URL + "user/plan", { headers: authHeader(), withCredentials: true, params: { billed: params.billed ? "yearly" : "monthly", currency_type: params.currencyType }, });
};

const postAutoReply = (params) => {
	return axios.post(API_URL + "user/auto_reply", params, { headers: authHeader(), withCredentials: true, });
};

const deleteAutoReply = async (params) => {
	return await axios.get(API_URL + `user/delete_auto_reply/${params}`, { headers: authHeader(), withCredentials: true, });
};

const getSubsDetails = (params) => {
	return axios.get(API_URL + "user/get_subs_details", { headers: authHeader(), withCredentials: true, });
};

const getLoginActivity = (params) => {
	return axios.get(API_URL + "user/login_activity", { headers: authHeader(), withCredentials: true, });
};

const getApiCred = (params) => {
	return axios.get(API_URL + "user/api_cred", { headers: authHeader(), withCredentials: true, params: { page: params.current, limit: params.pageSize, search: '' || params.search }, });
};

const getGenerateApiCred = (params) => {
	return axios.get(API_URL + "user/generate_api_cred", { headers: authHeader(), withCredentials: true, });
};

const deleteApiCred = async (params) => {
	return await axios.get(API_URL + `user/delete_api_cred/${params}`, { headers: authHeader(), withCredentials: true, });
};

const getEditApiCred = async (params) => {
	return await axios.get(API_URL + `user/edit_api_cred/${params}`, { headers: authHeader(), withCredentials: true, });
};

const postEditApiCred = (params) => {
	return axios.post(API_URL + "user/edit_api_cred", params, { headers: authHeader(), withCredentials: true, });
};

const postUpdateWebhooks = (params) => {
	return axios.post(API_URL + "user/update_webhooks", params, { headers: authHeader(), withCredentials: true, });
};

const postGenerateApiCred = (params) => {
	return axios.post(API_URL + "user/update_api_cred", params, { headers: authHeader(), withCredentials: true, });
};

const getUserProfileDetails = () => {
	return axios.get(API_URL + "user/user_profile", { headers: authHeader(), withCredentials: true, });
};

const getPaymentHistory = () => {
	return axios.get(API_URL + "user/payment_history", { headers: authHeader(), withCredentials: true, });
};

const getUserWebhooks = () => {
	return axios.get(API_URL + "user/user_webhooks", { headers: authHeader(), withCredentials: true, });
};

const postUserProfileDetails = (params) => {
	return axios.post(API_URL + "user/user_profile", params, { headers: authHeader(), withCredentials: true, });
};

const postSendMsg = (params) => {
	console.log('params', params);
	return axios.post(API_URL + "msg/send_msg", params, { headers: authHeader(), withCredentials: true, });
};

const postSendLoc = (params) => {
	console.log('params', params);
	return axios.post(API_URL + "msg/send_location", params, { headers: authHeader(), withCredentials: true, });
};

const postSendImg = (params) => {
	console.log('params', params);
	return axios.post(API_URL + "msg/send_img", params, { headers: authHeader(), withCredentials: true, });
};

const postSendFile = (params) => {
	console.log('params', params);
	return axios.post(API_URL + "msg/send_file", params, { headers: authHeader(), withCredentials: true, });
};

const postStripeCreateOrder = async (params) => {
	console.log('params', params);
	return await axios.post(API_URL + "payment/stripe/order", params, { headers: authHeader(), withCredentials: true, });
};

const postCreateOrder = async (params) => {
	console.log('params', params);
	return await axios.post(API_URL + "payment/razorpay/order", params, { headers: authHeader(), withCredentials: true, });
};

const postRazorpaySuccess = async (params) => {
	console.log('params', params);
	return await axios.post(API_URL + "payment/razorpay/success", params, { headers: authHeader(), withCredentials: true, });
};

const postPasswordChange = async (params) => {
	console.log('params', params);
	return await axios.post(API_URL + "user/change_password", params, { headers: authHeader(), withCredentials: true, });
};

const postUserContactUs = async (params) => {
	return await axios.post(API_URL + "user/contact_us", params, { headers: authHeader(), withCredentials: true, });
};

const logout = async () => {
	return await axios.get(API_URL + "user/logout");
};

export default {
	// getPublicContent,
	getDashboard,
	postSendMsg,
	postSendLoc,
	postSendImg,
	postSendFile,
	postStripeCreateOrder,
	postCreateOrder,
	postRazorpaySuccess,
	getUserProfileDetails,
	postUserProfileDetails,
	getLoginActivity,
	postPasswordChange,
	getSavedDetails,
	getAutoReply,
	postAutoReply,
	deleteAutoReply,
	getPlan,
	getSendMsg,
	getReceiveMsg,
	getPaymentHistory,
	logout,
	getGenerateApiCred,
	postGenerateApiCred,
	getApiCred,
	deleteApiCred,
	getEditApiCred,
	postEditApiCred,
	postUpdateWebhooks,
	getUserWebhooks,
	getSubsDetails,
	postUserContactUs
};
