import {
	CloseSquare,
	Discount,
	User,
	Paper,
	InfoSquare,
	Password,
	PaperPlus,
	Unlock,
	Bookmark,
	Message,
	ChevronDownCircle,
	ChevronUpCircle,
} from "react-iconly";

const pages = [
	{
		header: "MESSAGE",
	},
	{
		id: "send_message",
		title: "Send Message",
		icon: <ChevronUpCircle set="curved" />,
		navLink: "/user/send_message",
	},
	{
		id: "receive_message",
		title: "Receive Message",
		icon: <ChevronDownCircle set="curved" />,
		navLink: "/user/receive_message",
	},
];

export default pages;
