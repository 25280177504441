import React from "react";
import { MdMoneyOff } from "react-icons/md";
import { RiEarthLine, RiLinksFill, RiLink, RiMessage3Line } from "react-icons/ri";
import { HiOutlineCursorClick, HiOutlineServer } from "react-icons/hi";
import { BsReply } from "react-icons/bs";
import { BiTransferAlt, BiWorld } from "react-icons/bi";
import { TiArrowBackOutline } from "react-icons/ti";
import Slider from "react-slick";
import { Avatar, Button, Col, Row } from "antd";
import { Message, Star, Setting, Category, Graph } from "react-iconly";

export default function LandingFeatures() {
	// TODO: icon change karvana 6

	const featuresCard = [
		{
			icon: (
				<BiTransferAlt
					className="remix-icon da-text-color-primary da-ml-0"
					style={{ width: 24, height: 24, marginLeft: 0 }}
				/>
			),
			title: "2-way communication",
			subTitle: "Send as well as receive messages in our 2-way communication WhatsApp channel platform",
		},
		{
			icon: (
				<RiLinksFill
					className="remix-icon da-text-color-primary da-ml-0"
					style={{ width: 24, height: 24, marginLeft: 0 }}
				/>
			),
			title: "Webhook",
			subTitle:
				"Receive all Whatsapp Activity like receive msg, images and button reply on your url using webhooks",
		},
		{
			icon: (
				<HiOutlineCursorClick
					className="remix-icon da-text-color-primary da-ml-0"
					style={{ width: 24, height: 24, marginLeft: 0 }}
				/>
			),
			title: "Button & List Messages",
			subTitle: "Wotty allows you to create an interactive call to action button & list messages",
		},
		{
			icon: (
				<RiLink className="remix-icon da-text-color-primary da-ml-0" style={{ width: 24, height: 24, marginLeft: 0 }} />
			),
			title: "Powerful API",
			subTitle: "Send and receive WhatsApp messages globally with Wotty API for WhatsApp",
			// "For medium and large businesses, the WhatsApp Business API powers your communication with customers all over the world.",
		},
		{
			icon: (
				<TiArrowBackOutline
					className="remix-icon da-text-color-primary da-ml-0"
					style={{ width: 24, height: 24, marginLeft: 0 }}
				/>
			),
			title: "Auto reply",
			subTitle: "Respond to customers within seconds with Wotty Auto reply feature",
		},
		{
			icon: (
				<RiMessage3Line
					className="remix-icon da-text-color-primary da-ml-0"
					style={{ width: 24, height: 24, marginLeft: 0 }}
				/>
			),
			title: "Unlimited message",
			subTitle: "Wotty allows sending & receiving unlimited WhatsApp messages to your target customers",
		},
		{
			icon: (
				<HiOutlineServer
					className="remix-icon da-text-color-primary da-ml-0"
					style={{ width: 24, height: 24, marginLeft: 0 }}
				/>
			),
			title: "Best Uptime",
			subTitle: "We at Wotty strive to offer the best Network and Server uptime",
		},
		{
			icon: (
				<MdMoneyOff
					className="remix-icon da-text-color-primary da-ml-0"
					style={{ width: 24, height: 24, marginLeft: 0 }}
				/>
			),
			title: "No extra charges",
			subTitle: "Wotty do not take any extra charges on sending messages",
		},
		{
			icon: (
				<RiEarthLine
					className="remix-icon da-text-color-primary da-ml-0"
					style={{ width: 24, height: 24, marginLeft: 0 }}
				/>
			),
			title: "Send message Globally",
			subTitle: "Wotty can send SMS messages to billions of people in 150+ countries",
		},
	];

	const featuresCardMap = featuresCard.map((value, index) => (
		<Col key={index} xl={8} lg={12} md={12} sm={24} style={{ minHeight: "100%" }}>
			<div className="da-px-sm-8 da-px-16" style={{ height: "100%" }}>
				<div className="da-border-radius da-bg-black-10 da-bg-dark-100 da-p-18" style={{ height: "100%" }}>
					<Row align="top" justify="center" gutter={[20]} style={{ height: "100%" }}>
						<Col>
							<Avatar className="da-bg-black-0 da-bg-dark-90" size={55} icon={value.icon} />
						</Col>

						<Col flex="1 0 0">
							<h4 className="da-font-weight-500 da-mb-8">{value.title}</h4>
							<p className="h5 da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-0">
								{value.subTitle}
							</p>
						</Col>
					</Row>
				</div>
			</div>
		</Col>
	));

	return (
		<section className="da-landing-features da-pt-96 da-pb-120">
			<Row align="middle" justify="center">
				<Col lg={14} md={16} span={24} className="da-px-16 da-text-center da-mb-sm-64 da-mb-96">
					<h2 className="h1 da-mb-32">Why Wotty?</h2>

					<p className="h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30">
						Use tools that will help you set up and send high-quality WhatsApp campaigns
					</p>
				</Col>
			</Row>

			<Row gutter={[0, 30]}>{featuresCardMap}</Row>

			{/* <Row align="middle" justify="center">
				<Col xl={10} lg={14} md={16} span={24} className="da-px-16 da-text-center da-mt-sm-32 da-mt-64">
					<Button type="primary">Learn More</Button>
				</Col>
			</Row> */}
		</section>
	);
}
