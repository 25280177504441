import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Divider, Avatar, Row, Col, Typography, Tooltip } from "antd";
import { RiSettings3Line } from "react-icons/ri";
import avatar from "../../../../assets/images/memoji/avatar_getwotty.png";
import userService from "../../../../services/user.service";

const { Text } = Typography;
export default function MenuFooter(props) {
	const [name, setName] = useState("");
	useEffect(async () => {
		try {
			let response = await userService.getSavedDetails();
			console.log(response);
			if (response.name) {
				setName(response.name);
			}
		} catch (error) {
			console.log(error);
		}
	}, []);

	return (
		<Row className="da-sidebar-footer da-pb-24 da-px-24" align="middle" justify="space-between">
			<Divider className="da-border-color-black-20 da-mt-0" />

			<Col>
				<Row align="middle">
					<Avatar size={36} src={avatar} className="da-mr-8" />

					<div>
						<span className="da-d-block da-text-color-black-100 da-p1-body">
							<Tooltip placement="topLeft" title={name} onConfirm={confirm} okText="Yes" cancelText="No">
								<Text
									style={{
										width: 130,
									}}
									ellipsis={true}
								>
									{name}
								</Text>
							</Tooltip>
						</span>

						<Link to="/user/profile/details" className="da-badge-text" onClick={props.onClose}>
							View Profile
						</Link>
					</div>
				</Row>
			</Col>

			<Col>
				<Link to="/user/profile/security" onClick={props.onClose}>
					<RiSettings3Line className="remix-icon da-text-color-black-100" size={24} />
				</Link>
			</Col>
		</Row>
	);
}
