import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "antd";

export default function LandingHero() {
	return (
		<section className="da-landing-people da-py-sm-64 da-pt-96 da-pb-96 da-position-relative da-overflow-hidden">
			<div className="da-landing-container">
				<Row justify="center">
					<Col xl={15} lg={15} md={18} span={24} className="da-text-center">
						<h2 className="h1 da-mb-10">Start your 7 Days free trial</h2>

						<p className="h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-sm-0 da-mt-32 da-mb-0">
							The initial signup is completely free, no credit card is required and a 7-day free trial
							will enable you to get access to all the features
						</p>
					</Col>
					<Col span={24} className="da-px-16 da-text-center da-mt-sm-32 da-mt-64">
						<Link to="/auth/register">
							<Button className="" type="primary">
								Start for free now
							</Button>
						</Link>
					</Col>
				</Row>
			</div>
		</section>
	);
}
