import React from "react";

import { Col, Row } from "antd";

export default function LandingCompanies() {
	return (
		<section
			className="da-bg-black-10 da-bg-dark-100 da-landing-companies da-overflow-hidden da-pt-96 da-pb-120 da-position-relative da-overflow-hidden"
			style={{ paddingTop: "180px", paddingBottom: "180px" }}
		>
			{/* <div className="da-landing-people-circle da-bg-success-1"></div> */}
			<div className="da-landing-container">
				<Row className="da-mb-96" justify="center">
					<Col xl={15} lg={15} md={18} span={24} className="da-text-center">
						<h2 className="h2 da-mb-4">Connectivity and beyond</h2>

						<p className="h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-sm-0 da-mt-32 da-mb-0">
							Connecting through Wotty will get you the following benefits
						</p>
					</Col>
				</Row>
				<Row gutter={[50, 24]} align="middle" justify="center" className="da-mx-sm-4">
					<Col className="da-px-0 da-text-center" lg={4} md={6} sm={6} xs={8}>
						<h1>💌</h1>
						<Col className="da-px-0">
							<h4 className="h4">Message</h4>
						</Col>
					</Col>
					<Col className="da-px-0 da-text-center" lg={4} md={6} sm={6} xs={8}>
						<h1>🖼️</h1>
						<Col className="da-px-0">
							<h4>Images</h4>
						</Col>
					</Col>
					<Col className="da-px-0 da-text-center" lg={4} md={6} sm={6} xs={8}>
						<h1>📃</h1>
						<Col className="da-px-0">
							<h4>Document</h4>
						</Col>
					</Col>
					<Col className="da-px-0 da-text-center" lg={4} md={6} sm={6} xs={8}>
						<h1>🔗</h1>
						<Col className="da-px-0">
							<h4>Links</h4>
						</Col>
					</Col>
					<Col className="da-px-0 da-text-center" lg={4} md={6} sm={6} xs={8}>
						<h1>🗺️</h1>
						<Col className="da-px-0">
							<h4 className="h4">Location</h4>
						</Col>
					</Col>
					<Col className="da-px-0 da-text-center" lg={4} md={6} sm={6} xs={8}>
						<h1>😇</h1>
						<Col className="da-px-0">
							<h4 className="h4">Emojis</h4>
						</Col>
					</Col>
				</Row>
			</div>
		</section>
	);
}
