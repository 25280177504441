import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { Button, Col, Menu, Row, Dropdown } from "antd";
import { RiMenuFill, RiArrowDropDownLine, RiMailLine } from "react-icons/ri";

import MenuLogo from "../../layout/components/menu/logo";
const { SubMenu } = Menu;

export default function LandingHeader(props) {
	console.log("props", props);
	const history = useHistory();

	const executeScrollByProp = (e) => {
		console.log(e.key);
		// let scrollTo = e.currentTarget.getAttribute("data-scroll-to");
		let scrollTo = e.key;
		console.log("header inside", scrollTo, window.location.href);
		if (window.location.href.includes("/pages/solutions")) {
			props.executeScroll(scrollTo);
		} else {
			history.push("/pages/solutions#" + scrollTo);
		}
	};

	const location = useLocation();
	const { pathname } = location;
	const splitLocation = pathname.split("/");

	// Redux
	const customise = useSelector((state) => state.customise);

	const loginButton = (
		<Link to="/auth/login">
			<Button type="primary" className="da-px-sm-16 da-py-sm-8 da-px-32 da-py-16 da-ml-sm-0 da-ml-8">
				Login
			</Button>
		</Link>
	);

	const signUpButton = (
		<Link to="/auth/register">
			<Button
				type="text"
				className="da-px-sm-16 da-py-sm-8 da-px-32 da-py-16 da-ml-sm-8 da-text-color-black-80 da-text-color-dark-30"
			>
				Sign Up
			</Button>
		</Link>
	);

	const menuItems = (
		<>
			<Menu.Item
				key={1}
				className={`
				da-border-radius
				${splitLocation[splitLocation.length - 1] === "" ? "ant-menu-item-selected" : "ant-menu-item-selected-in-active"}
			`}
			>
				<Link to="/">Home</Link>
			</Menu.Item>
			<SubMenu
				key="SubMenu"
				title="Solutions"
				className={`
							da-border-radius
							${
								splitLocation[splitLocation.length - 1] === "solutions"
									? "ant-menu-item-selected"
									: "ant-menu-item-selected-in-active"
							}
						`}
			>
				<Menu.Item key="allSolutions">
					<Link to="/pages/solutions">All</Link>
				</Menu.Item>
				<Menu.Item key="ecommerceRef" onClick={executeScrollByProp}>
					Ecommerce
				</Menu.Item>
				<Menu.Item key="logisticsRef" onClick={executeScrollByProp}>
					Logistics
				</Menu.Item>
				<Menu.Item key="retailRef" onClick={executeScrollByProp}>
					Retail
				</Menu.Item>
				<Menu.Item key="healthcareRef" onClick={executeScrollByProp}>
					Healthcare
				</Menu.Item>
				<Menu.Item key="automotiveRef" onClick={executeScrollByProp}>
					Automotive
				</Menu.Item>
				<Menu.Item key="realestateRef" onClick={executeScrollByProp}>
					Real Estate
				</Menu.Item>
				<Menu.Item key="foodbeverageRef" onClick={executeScrollByProp}>
					Food & Beverage
				</Menu.Item>
				<Menu.Item key="travelRef" onClick={executeScrollByProp}>
					Travel
				</Menu.Item>
				<Menu.Item key="educationRef" onClick={executeScrollByProp}>
					Education
				</Menu.Item>
				<Menu.Item key="bankingRef" onClick={executeScrollByProp}>
					Banking & Fin Tech
				</Menu.Item>
			</SubMenu>
			<Menu.Item
				key={2}
				className={`
							da-border-radius
							${splitLocation[splitLocation.length - 1] === "pricing" ? "ant-menu-item-selected" : "ant-menu-item-selected-in-active"}
						`}
			>
				<Link to="/pages/pricing">Pricing</Link>
			</Menu.Item>
			<Menu.Item
				key={3}
				className={`
				da-border-radius
				${
					splitLocation[splitLocation.length - 1] === "landing_faq"
						? "ant-menu-item-selected"
						: "ant-menu-item-selected-in-active"
				}
			`}
			>
				<Link to="/pages/landing_faq">FAQ</Link>
			</Menu.Item>
			<Menu.Item
				key={4}
				className={`
				da-border-radius
				${
					splitLocation[splitLocation.length - 1] === "contact_us"
						? "ant-menu-item-selected"
						: "ant-menu-item-selected-in-active"
				}
			`}
			>
				<Link to="/pages/contact_us">Contact Us</Link>
			</Menu.Item>
		</>
	);

	return (
		<header className="da-my-8">
			<div className="da-landing-container">
				<Row align="middle" justify="space-between">
					<Col>
						<MenuLogo />
					</Col>

					<Col className="da-landing-header-mobile-button">
						<Dropdown
							placement="bottomRight"
							trigger="click"
							overlay={
								<Menu mode="vertical" theme="light" className="da-bg-dark-90">
									<Menu.ItemGroup>{menuItems}</Menu.ItemGroup>

									<Menu.ItemGroup>
										<Menu.Item key={8}>
											<Row justify="space-between">
												<Col>{loginButton}</Col>

												<Col>{signUpButton}</Col>
											</Row>
										</Menu.Item>
									</Menu.ItemGroup>
								</Menu>
							}
						>
							<Button
								type="text"
								icon={
									<RiMenuFill
										size={24}
										className="remix-icon da-text-color-black-80 da-text-color-dark-30"
									/>
								}
							/>
						</Dropdown>
					</Col>

					<Col flex="1 0 0" className="da-px-24 da-landing-header-menu">
						<Menu mode="horizontal" className="da-d-flex-full-center da-bg-dark-90" theme="light">
							{menuItems}
						</Menu>
					</Col>

					<Col className="da-landing-header-buttons">
						{signUpButton}
						{loginButton}
					</Col>
				</Row>
			</div>
		</header>
	);
}
