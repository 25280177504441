import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RiCheckboxCircleLine, RiCloseCircleLine, RiCloseFill, RiCalendarLine } from "react-icons/ri";

import { Button, Col, Row, Form, Input, Checkbox, Dropdown, Menu, message } from "antd";
import Yoda from "../../assets/images/logo/getwotty_logo_test_2.svg";
import authService from "../../services/auth.service";

export default function LandingFooter(props) {
	const [newsLetterForm] = Form.useForm();
	const [loading, setLoading] = useState(false);
	console.log("props", props);
	const history = useHistory();

	const executeScrollByProp = (e) => {
		let scrollTo = e.currentTarget.getAttribute("data-key");
		console.log("header inside", scrollTo, window.location.href);
		if (window.location.href.includes("/pages/solutions")) {
			props.executeScroll(scrollTo);
		} else {
			history.push("/pages/solutions#" + scrollTo);
		}
	};

	const errorMsg = (msg) => {
		message.error({
			content: msg,
			icon: <RiCloseCircleLine className="remix-icon" />,
			duration: 5,
		});
	};

	const successMsg = (msg) => {
		message.success({
			content: msg,
			icon: <RiCheckboxCircleLine className="remix-icon" />,
			duration: 5,
		});
	};

	const onFinish = async (values) => {
		setLoading(true);
		try {
			let response = await authService.postNewsLetter(values);
			console.log(response);
			if (response.data.status === "success") {
				successMsg(response.data.msg);
				setLoading(false);
				newsLetterForm.resetFields();
			} else {
				setLoading(false);
				console.error("error");
				errorMsg(error.response.data.msg);
			}
		} catch (error) {
			setLoading(false);
			console.error(error);
			if (error.response) {
				console.error(error.response.data);
				console.error(error.response.status);
				console.error(error.response.headers);
				if (error.response.status === 422) {
					errorMsg(error.response.data.msg);
					// alert(error.response.data.errors[0].msg);
				}
			}
		}
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<footer className="da-position-relative da-bg-black-20 da-bg-dark-100 da-pt-sm-64 da-pt-120 da-pb-24 da-overflow-hidden da-px-16">
			<div className="da-landing-footer-circle da-bg-color-black-60 da-bg-color-dark-40"></div>

			<div className="da-landing-container">
				<Row justify="space-between" className="da-mb-sm-64 da-mb-120">
					<Col lg={8} md={16} span={24}>
						<Col span={24} className="da-mb-16 da-d-flex da-align-items-end">
							<img className="da-logo" src={Yoda} alt="logo" />
							<span
								className="h3 d-font-weight-800 da-mb-6"
								style={{ fontWeight: 700, marginLeft: "10px" }}
							>
								Wotty
							</span>
						</Col>
						<p className="h5 da-font-weight-500 da-text-color-black-80 da-text-color-dark-30 da-mb-32">
							Wotty leverage conversations to propel business growth, help businesses provide a superior
							customer experience by bringing the focus back on prompt and timely conversations.
						</p>

						{/* <Button className="da-bg-black-100 da-text-color-black-0">Ask Question</Button> */}
					</Col>

					<Col lg={14} span={24} className="da-mt-md-64">
						<Row>
							<Col flex="1 1 1" className="da-mr-sm-32 da-mb-sm-32">
								<div className="da-d-flex da-w-100">
									<span className="da-p1-body da-font-weight-700">SOLUTIONS</span>
								</div>
								<div className="da-d-flex da-w-100">
									<Button
										type="link"
										className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16 da-p-0"
										data-key="ecommerceRef"
										onClick={executeScrollByProp}
										style={{ minHeight: "auto" }}
									>
										Ecommerce
									</Button>
								</div>
								<div className="da-d-flex da-w-100">
									<Button
										type="link"
										className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16 da-p-0"
										data-key="logisticsRef"
										onClick={executeScrollByProp}
										style={{ minHeight: "auto" }}
									>
										Logistics
									</Button>
								</div>
								<div className="da-d-flex da-w-100">
									<Button
										type="link"
										className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16 da-p-0"
										data-key="retailRef"
										onClick={executeScrollByProp}
										style={{ minHeight: "auto" }}
									>
										Retail
									</Button>
								</div>
								<div className="da-d-flex da-w-100">
									<Button
										type="link"
										className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16 da-p-0"
										data-key="healthcareRef"
										onClick={executeScrollByProp}
										style={{ minHeight: "auto" }}
									>
										Healthcare
									</Button>
								</div>
								<div className="da-d-flex da-w-100">
									<Button
										type="link"
										className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16 da-p-0"
										data-key="automotiveRef"
										onClick={executeScrollByProp}
										style={{ minHeight: "auto" }}
									>
										Automotive
									</Button>
								</div>
								<div className="da-d-flex da-w-100">
									<Button
										type="link"
										className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16 da-p-0"
										data-key="realestateRef"
										onClick={executeScrollByProp}
										style={{ minHeight: "auto" }}
									>
										Real Estate
									</Button>
								</div>
								<div className="da-d-flex da-w-100">
									<Button
										type="link"
										className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16 da-p-0"
										data-key="foodbeverageRef"
										onClick={executeScrollByProp}
										style={{ minHeight: "auto" }}
									>
										Food & Beverage
									</Button>
								</div>
								<div className="da-d-flex da-w-100">
									<Button
										type="link"
										className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16 da-p-0"
										data-key="travelRef"
										onClick={executeScrollByProp}
										style={{ minHeight: "auto" }}
									>
										Travel
									</Button>
								</div>
								<div className="da-d-flex da-w-100">
									<Button
										type="link"
										className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16 da-p-0"
										data-key="educationRef"
										onClick={executeScrollByProp}
										style={{ minHeight: "auto" }}
									>
										Education
									</Button>
								</div>
								<div className="da-d-flex da-w-100">
									<Button
										type="link"
										className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16 da-p-0"
										data-key="bankingRef"
										onClick={executeScrollByProp}
										style={{ minHeight: "auto" }}
									>
										Banking & Fin Tech
									</Button>
								</div>
							</Col>

							<Col flex="1 0 0" className="da-mr-sm-32">
								<span className="da-p1-body da-font-weight-700 da-mb-16">MORE</span>

								<Link
									href="#"
									className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16"
									to="/pages/pricing"
								>
									Pricing
								</Link>
								<Link
									href="#"
									className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16"
									style={{ whiteSpace: "nowrap" }}
									to="/pages/contact_us"
								>
									Contact Us
								</Link>
								<Link
									href="#"
									className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16"
									to="/pages/landing_faq"
								>
									FAQ
								</Link>
								<Link
									href="#"
									className="da-d-block h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-mt-16"
									to="/pages/about"
								>
									About Us
								</Link>
							</Col>

							<Col flex="0 0 300px" className="">
								<span className="da-p1-body da-font-weight-700 da-mb-16">CONTACTS</span>

								<p className="h5 da-font-weight-400 da-text-color-black-80 da-text-color-dark-30 da-my-16">
									Feelfree to get in touch with us <br />
									via send us a message
								</p>

								<a className="da-mb-16" href="mailto:care@getwotty.com">
									care@getwotty.com
								</a>

								<Col span={24} className="da-mt-32">
									<Form
										form={newsLetterForm}
										layout="vertical"
										name="contact-form"
										initialValues={{ remember: true }}
										onFinish={onFinish}
										onFinishFailed={onFinishFailed}
									>
										<Form.Item
											className="da-mb-10"
											label="Subscribe to our Newsletter"
											name="email"
											rules={[
												{ required: true, message: "Please input your Email for Newsletter!" },
												{
													type: "email",
													message: "Please input a valid Email for Newsletter!",
												},
											]}
										>
											<Input placeholder="Write your email" />
										</Form.Item>
										<Form.Item>
											<Button
												htmlType="submit"
												className="da-bg-black-100 da-text-color-black-0"
												style={{ width: "100%" }}
											>
												Submit
											</Button>
										</Form.Item>
									</Form>
								</Col>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row
					align="middle"
					justify="space-between"
					className="da-landing-footer-copyright da-border-top-1 da-border-color-dark-80 da-pt-24 da-mt-24"
				>
					<Col>
						<p className="da-p1-body da-mb-sm-16 da-mb-0">© Wotty 2021-22, All Rights Reserved.</p>
					</Col>

					<Col>
						<Row align="middle">
							<Col>{/* <p style={{ fontWeight: 500 }}>Terms of Services</p> */}</Col>

							<Col className="da-ml-18">
								<Link to="/pages/refund-policy">
									<p>Refund Policy</p>
								</Link>
							</Col>

							<Col className="da-ml-18">
								<Link to="/pages/terms">
									<p>Terms & Conditions</p>
								</Link>
							</Col>

							<Col className="da-ml-18">
								<Link to="/pages/privacy">
									<p>Privacy Policy</p>
								</Link>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
		</footer>
	);
}
