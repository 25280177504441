import { Buy, Document, Folder} from "react-iconly";

const components = [
    {
        header: "BILLING",
    },
    {
        id: "plan",
        title: "Plan",
        icon: <Buy set="curved"/>,
        navLink: "/user/plan",
    },
    {
        id: "payment_history",
        title: "Purchase History",
        icon: <Folder set="curved"/>,
        navLink: "/user/payment_history",
    },
    
];

export default components